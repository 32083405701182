export default async function adminProductionOnly({ to, next, store }) {
  if (process.env.VUE_APP_ENVIRONMENT === 'production') {
    const user = store.getters['auth/user']
    if (user?.role?.name !== 'admin') {
      return next({
        name: 'dashboard',
        params: {
          nextUrl: to.fullPath,
        },
      })
    }
  }
  return next()
}
