const TYPE = {
  LOAD_SEARCH_CAMPAIGNS: 'LOAD_SEARCH_CAMPAIGNS',
  LOAD_SEARCH_CAMPAIGNS_ALL: 'LOAD_SEARCH_CAMPAIGNS_ALL',

  SET_SELECTED_CAMPAIGN: 'SET_SELECTED_CAMPAIGN',
  RESET_SELECTED_CAMPAIGN: 'RESET_SELECTED_CAMPAIGN',
  CLEAR: 'CLEAR',
}
export default {
  namespaced: true,
  state: {
    campaigns: [], // managed by Brightbid
    campaignsAll: [], // managed && not managed
    selectedCampaign: null,
  },
  getters: {},
  actions: {
    loadSearchCampaigns({ commit }, data) {
      commit(TYPE.LOAD_SEARCH_CAMPAIGNS, data)
    },
    async loadSearchCampaignsAll({ dispatch, commit }, { siteId, exclusion }) {
      try {
        const { data: campaigns } = await axios.get(`/search/site/${siteId}/campaigns`, {
          params: {
            managed: false,
            exclude_campaign_types: exclusion,
          },
        })
        commit(TYPE.LOAD_SEARCH_CAMPAIGNS_ALL, campaigns)
      } catch (error) {
        await dispatch(
          'toast/loadToast',
          {
            title: 'Error',
            message: 'Failed to load campaigns. Please try again later.',
            type: 'error',
          },
          { root: true },
        )
      }
    },
    loadSelectedCampaign({ commit }, data) {
      commit(TYPE.SET_SELECTED_CAMPAIGN, data)
    },
    clear({ commit }) {
      commit(TYPE.CLEAR)
    },
  },
  mutations: {
    [TYPE.LOAD_SEARCH_CAMPAIGNS](state, data) {
      if (!data) {
        state.campaigns = []
        return
      }
      state.campaigns = data
    },
    [TYPE.LOAD_SEARCH_CAMPAIGNS_ALL](state, data) {
      if (!data) {
        state.campaignsAll = []
        return
      }
      state.campaignsAll = data
    },
    [TYPE.SET_SELECTED_CAMPAIGN](state, data) {
      if (!data) {
        state.selectedCampaign = null
        return
      }
      state.selectedCampaign = data
    },
    [TYPE.CLEAR](state) {
      state.campaigns = []
      state.campaignsAll = []
      state.selectedCampaign = null
    },
  },
}
