import { getErrorStatus } from '@/utils/util'
import * as Sentry from '@sentry/vue'

const TYPE = {
  LOAD_CMS_SITE: 'LOAD_CMS_SITE',
  LOAD_ORGANIZATION_CREDIT: 'LOAD_ORGANIZATION_CREDIT',
  LOAD_MONITORING_GROUPS: 'LOAD_MONITORING_GROUPS',
  LOAD_SELECTED_MONITORING_GROUP: 'LOAD_SELECTED_MONITORING_GROUP',
  CLEAR: 'CLEAR',
}

const SELECTED_MONITORING_GROUP = 'selected_monitoring_group'

export default {
  namespaced: true,
  state: {
    selectedMonitoringGroup: JSON.parse(localStorage.getItem(SELECTED_MONITORING_GROUP)) || null,
    cmsSite: null,
    organizationCredit: null,
    monitoringGroups: [],
  },
  getters: {},
  actions: {
    async loadCmsSite({ dispatch, commit }, siteId) {
      try {
        const { data: site } = await axios.get(`/cms/site/${siteId}`)
        commit(TYPE.LOAD_CMS_SITE, site)
        return site
      } catch (e) {
        // TODO: Set error state
        if (getErrorStatus(e) !== 404) {
          Sentry.captureException(e)
          await dispatch(
            'toast/loadToast',
            {
              title: 'Error',
              message: 'Failed to get site. Please try again later.',
              type: 'error',
            },
            { root: true },
          )
        }
        return null
      }
    },
    async loadOrganizationCredit({ dispatch, commit }, organizationId) {
      try {
        const { data: credit } = await axios.get(`/common/organization/${organizationId}/cms-credit/usage`)
        commit(TYPE.LOAD_ORGANIZATION_CREDIT, credit)
        return credit
      } catch (e) {
        if (getErrorStatus(e) !== 404) {
          Sentry.captureException(e)
          await dispatch(
            'toast/loadToast',
            {
              title: 'Error',
              message: 'Failed to get organization credit limit. Please try again later.',
              type: 'error',
            },
            { root: true },
          )
        }
        return null
      }
    },
    async loadMonitoringGroups({ dispatch, commit }, siteId) {
      try {
        const { data: monitoringGroups } = await axios.get(`/cms/site/${siteId}/monitoring-job/v3`)
        commit(TYPE.LOAD_MONITORING_GROUPS, monitoringGroups)
        return monitoringGroups
      } catch (error) {
        // TODO: Set error state
        await dispatch(
          'toast/loadToast',
          {
            title: 'Error',
            message: 'Failed to get monitoring groups. Please try again later.',
            type: 'error',
          },
          { root: true },
        )
      }
    },
    loadSelectedMonitoringGroup({ commit }, data) {
      commit(TYPE.LOAD_SELECTED_MONITORING_GROUP, data)
      // fetch related data to the selected monitoring job
    },
    clear({ commit }) {
      commit(TYPE.CLEAR)
    },
  },
  mutations: {
    [TYPE.LOAD_CMS_SITE](state, data) {
      if (!data) {
        state.cmsSite = null
        return
      }
      state.cmsSite = data
    },
    [TYPE.LOAD_ORGANIZATION_CREDIT](state, data) {
      if (!data) {
        state.organizationCredit = null
        return
      }
      state.organizationCredit = data
    },
    [TYPE.LOAD_MONITORING_GROUPS](state, data) {
      if (!data) {
        state.monitoringGroups = []
        return
      }
      state.monitoringGroups = data
    },
    [TYPE.LOAD_SELECTED_MONITORING_GROUP](state, data) {
      if (!data) {
        state.selectedMonitoringGroup = null
        localStorage.removeItem(SELECTED_MONITORING_GROUP)
        return
      }
      state.selectedMonitoringGroup = data
      localStorage.setItem(SELECTED_MONITORING_GROUP, JSON.stringify(data))
    },
    [TYPE.CLEAR](state) {
      state.cmsSite = null
      state.organizationCredit = null
      state.monitoringGroups = []
      state.selectedMonitoringGroup = null

      localStorage.removeItem(SELECTED_MONITORING_GROUP)
    },
  },
}
