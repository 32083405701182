import Vue from 'vue'
import Vuex from 'vuex'

import auth from '@/store/modules/auth/auth'
import account from '@/store/modules/account/account'
import budget from '@/store/modules/budget/budget'
import billing from '@/store/modules/billing/billing'
import campaign from '@/store/modules/campaign/campaign'
import card from '@/store/modules/card/card'
import check from '@/store/modules/feed/check'
import country from '@/store/modules/country/country'
import language from '@/store/modules/language/language'
import currency from '@/store/modules/currency/currency'
import date from '@/store/modules/date/date'
import feed from '@/store/modules/feed/feed'
import feedback from '@/store/modules/feedback/feedback'
import invoice from '@/store/modules/invoice/invoice'
import manager from '@/store/modules/manager/manager'
import modal from '@/store/modules/modal/modal'
import store from '@/store/modules/store/store'
import filter from '@/store/modules/campaign/filter'
import user from '@/store/modules/user/user'
import tier from '@/store/modules/subscription/tier'
import subscription from '@/store/modules/subscription/subscription'
import onBoardingForm from '@/store/modules/onboarding/onboarding-form'
import productOverview from '@/store/modules/product/overview'
import alert from '@/store/modules/alert/alert'
import searchTermsOverview from '@/store/modules/search-terms/overview'
import organization from '@/store/modules/organization/organization'
import site from '@/store/modules/site/site'
import portfolio from '@/store/modules/portfolio/portfolio'
import offerings from '@/store/modules/offerings/offerings'
import indexDB from '@/store/modules/indexdb/indexdb'
import notifications from '@/store/modules/site/notifications'
import dashboardCharts from '@/store/modules/charts/dashboardCharts'
import keywords from '@/store/modules/keywords/keywords'

// v2
import search from '@/store/modules_v2/site/search'
import competitorMonitoring from '@/store/modules_v2/site/search/competitorMonitoring'
import competitorMonitoringV2 from '@/store/modules_v2/site/search/competitor-monitoring-v2'
import toast from '@/store/modules_v2/toast'
import hub from '@/store/modules_v2/hub'
import audit from '@/store/modules_v2/audit/audit'
import auditCheck from '@/store/modules_v2/audit/auditCheck'

import { deleteBillingForm, deleteFeedForm, deleteSelectedStoreId, readSelectedStoreId } from '@/utils/storage/local'

Vue.use(Vuex)

const TYPE = { SET_COOKIES_ACCEPTED: 'SET_COOKIES_ACCEPTED', SET_APP_INITIALIZED: 'SET_APP_INITIALIZED' }

export default new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  modules: {
    auth,
    account,
    budget,
    billing,
    campaign,
    card,
    check,
    country,
    language,
    currency,
    date,
    feed,
    feedback,
    filter,
    invoice,
    manager,
    modal,
    store,
    user,
    tier,
    subscription,
    onBoardingForm,
    productOverview,
    alert,
    searchTermsOverview,
    indexDB,
    organization,
    site,
    portfolio,
    offerings,
    notifications,
    dashboardCharts,
    keywords,

    // v2
    search,
    competitorMonitoring,
    competitorMonitoringV2,
    toast,
    hub,
    audit,
    auditCheck,
  },
  state: {
    cookiesAccepted: true,
    appInitialized: false,
  },
  getters: {
    isCookiesAccepted(state) {
      return state.cookiesAccepted
    },
    isAppInitialized(state) {
      return state.appInitialized
    },
  },
  actions: {
    setCookiesAccepted({ commit }, accepted) {
      commit(TYPE.SET_COOKIES_ACCEPTED, accepted)
    },
    async fetchInitialConsoleData({ dispatch, getters, commit }) {
      commit(TYPE.SET_APP_INITIALIZED, true)

      await Promise.all([
        dispatch('store/loadAllStores', null, { root: true }),
        dispatch('manager/loadAccountManager', null, { root: true }),
        dispatch('billing/loadAccountBillings', null, { root: true }),
        dispatch('invoice/loadCurrencies', null, { root: true }),
        dispatch('card/loadCards', null, { root: true }),
      ])

      const stores = getters['store/stores']
      const user = getters['auth/user']
      const currentStoreId = readSelectedStoreId({ userId: user.id }) || (stores.length > 0 ? stores[0].id : null)
      if (currentStoreId) {
        await dispatch('store/selectStore', currentStoreId, { root: true })
        await dispatch('onBoardingForm/onInit', null, { root: true })
      }
      commit(TYPE.SET_APP_INITIALIZED, false)

      return Promise.resolve()
    },
    clearSensitiveData({ dispatch, getters }) {
      const user = getters['auth/user']
      dispatch('auth/clear', null, { root: true })
      dispatch('account/clear', null, { root: true })
      dispatch('billing/clear', null, { root: true })
      dispatch('invoice/clear', null, { root: true })
      dispatch('manager/clear', null, { root: true })
      dispatch('store/clear', null, { root: true })
      dispatch('user/clear', null, { root: true })
      dispatch('card/clear', null, { root: true })
      deleteBillingForm()
      deleteFeedForm()
      deleteSelectedStoreId(user.id)
    },
    clearCompetitorMonitoring({ dispatch }) {
      dispatch('competitorMonitoring/clear', null, { root: true })
      dispatch('competitorMonitoringV2/clear', null, { root: true })
    },
    clearSearch({ dispatch }) {
      dispatch('search/clear', null, { root: true })
    },
  },
  mutations: {
    [TYPE.SET_COOKIES_ACCEPTED](state, accepted) {
      state.cookiesAccepted = accepted
    },
    [TYPE.SET_APP_INITIALIZED](state, init) {
      state.appInitialized = init
    },
  },
})
