import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import ConsoleLayout from '@/layouts/console'
import AuditLayout from '@/layouts/auditlayout'
import FullPageLayout from '@/layouts/fullpage'
import auth from '@/middleware/auth'
import auditAuth from '@/middleware/auditAuth'
import onBoarding from '@/middleware/onBoarding'
import user from '@/store/modules/auth/auth'
import externalUserProtection from '@/middleware/externalUserProtection'
import adminProductionOnly from '../middleware/adminProductionOnly'

Vue.use(VueRouter)

const role = user.state.user ? user.state.user.role.name : null

const routes = [
  {
    path: '',
    component: FullPageLayout,
    props: () => ({ shinyBackground: true }),
    children: [
      {
        path: 'signin',
        name: 'signin',
        component: () => import(/* webpackChunkName: "signin" */ '@/views/auth/Signin.vue'),
      },
      {
        path: 'limited-use-policy',
        name: 'limited:use:policy',
        component: () => import(/* webpackChunkName: "signin" */ '@/views/auth/LimitedUsePolicy.vue'),
      },
      {
        path: 'privacy-policy',
        name: 'privacy:policy',
        component: () => import(/* webpackChunkName: "signin" */ '@/views/auth/PrivacyPolicy.vue'),
      },
      {
        path: 'signup',
        name: 'signup',
        component: () => import(/* webpackChunkName: "signin" */ '@/views/auth/Signup.vue'),
      },
      {
        path: 'onboarding',
        name: 'onboarding-wizard',
        meta: { middleware: [auth] },
        component: () => import('@/views/onboarding'),
      },
      {
        path: 'onboarding-v2',
        name: 'onboarding-wizard-v2',
        meta: { middleware: [auth, adminProductionOnly] },
        component: () => import('@/views/onboarding_v2'),
      },
    ],
  },
  {
    path: '/console',
    component: ConsoleLayout,
    meta: { middleware: [auth, onBoarding] },
    children: [
      {
        path: 'portfolio',
        name: 'portfolio',
        component: () => import('@/views/portfolio/Portfolio.vue'),
      },
      {
        path: 'dashboard',
        name: 'dashboard',
        component: () => import('@/views/Dashboard.vue'),
      },
      {
        path: 'site/reports',
        component: () => import('@/views/reports/Index.vue'),
        children: [
          {
            path: 'datastudio',
            name: 'site:reports:datastudio',
            component: () => import('@/views/reports/DataStudioReport.vue'),
          },
          {
            path: 'audit',
            name: 'site:reports:audit',
            component: () => import('@/views/reports/Audit.vue'),
          },
          {
            path: 'audit/history',
            name: 'site:reports:audit:history',
            component: () => import('@/views/reports/AuditHistory.vue'),
          },
        ],
      },
      // SHOPPING ROUTES
      // {
      //   path: 'site/shopping',
      //   component: () => import('@/views/reports/Index.vue'),
      //   children: [
      //     {
      //       path: 'products',
      //       name: 'site:shopping:products',
      //       component: () => import('@/views/UnderConstruction.vue'),
      //     },
      //     {
      //       path: 'feeds',
      //       name: 'site:shopping:feeds',
      //       component: () => import('@/views/UnderConstruction.vue'),
      //     },
      //     {
      //       path: 'performance-labels',
      //       name: 'site:shopping:performance:labels',
      //       component: () => import('@/views/UnderConstruction.vue'),
      //     },
      //     {
      //       path: 'brightbid-css',
      //       name: 'site:shopping:brightbid:css',
      //       component: () => import('@/views/UnderConstruction.vue'),
      //     },
      //   ],
      // },

      // SITE SEARCH ROUTES
      {
        path: 'site/search',
        component: () => import('@/views/site/search'),
        children: [
          {
            path: 'keyword-suggestions',
            name: 'site:search:keyword:suggestions',
            component: () => import('@/views/keywords_suggestions'),
          },
          {
            path: 'ad-generator',
            name: 'site:search:ad:generator',
            component: () => import('@/views/ad_generator'),
          },
          {
            path: 'competitor-monitoring',
            name: 'competitor-monitoring',
            component: () => import('@/views/site/search/competitor_monitoring'),
            children: [
              {
                path: 'onboarding',
                name: 'onboarding',
                component: () => import('@/views/site/search/competitor_monitoring/cms_onboarding'),
              },
              {
                path: 'overview',
                name: 'overview',
                component: () => import('@/views/site/search/competitor_monitoring/overview'),
              },
              {
                path: 'infringements-detector',
                name: 'infringements-detector',
                component: () => import('@/views/site/search/competitor_monitoring/infringements_detector'),
              },
              {
                path: 'all-competitors',
                name: 'all-competitors',
                component: () => import('@/views/site/search/competitor_monitoring/all_competitors'),
              },
            ],
          },
          {
            path: 'competitor-monitoring-v2',
            name: 'competitor-monitoring-v2',
            component: () => import('@/views/site/search/competitor_monitoring_v2'),
            children: [
              {
                path: 'onboarding',
                name: 'onboarding-v2',
                component: () => import('@/views/site/search/competitor_monitoring_v2/onboarding'),
              },
              {
                path: 'overview',
                name: 'overview-v2',
                component: () => import('@/views/site/search/competitor_monitoring_v2/overview'),
              },
              {
                path: 'infringements-detector',
                name: 'infringements-detector-v2',
                component: () => import('@/views/site/search/competitor_monitoring_v2/infringements'),
              },
              {
                path: 'all-competitors',
                name: 'all-competitors-v2',
                component: () => import('@/views/site/search/competitor_monitoring_v2/competitors'),
              },
              {
                path: 'all-groups',
                name: 'all-groups',
                component: () => import('@/views/site/search/competitor_monitoring_v2/all-groups'),
              },
            ],
          },
          // {
          //   path: 'ads-generator',
          //   name: 'site:search:ads:generator',
          // },
          // {
          //   path: 'competitor-discovery',
          //   name: 'site:search:competitor:discovery',
          // },
          // {
          //   path: 'keyword-intent',
          //   name: 'site:search:keyword:intent',
          // },
          {
            path: 'ai-activity-log',
            name: 'site:search:ai:activity:log',
            component: () => import('@/views/ai_activity_log/AiActivityLog.vue'),
          },
        ],
      },
      {
        path: 'site/settings',
        component: () => import('@/views/site/settings/Index.vue'),
        children: [
          {
            path: 'site',
            name: 'site:settings:site',
            component: () => import('@/views/site/settings/SiteSettings.vue'),
          },
          {
            path: 'integrations',
            name: 'site:settings:integrations',
            component: () => import('@/views/site/settings/Integrations.vue'),
          },
          {
            path: 'notifications',
            name: 'site:settings:notifications',
            component: () => import('@/views/UnderConstruction.vue'),
          },
        ],
      },
      {
        path: 'settings',
        name: 'settings',
        meta: { breadcrumb: false },
        component: () => import('@/views/settings/Index.vue'),
        children: [
          {
            path: 'user-management',
            name: 'settings:user-management',
            component: () => import('@/views/UserManagement.vue'),
          },
          {
            path: 'organization',
            meta: { breadcrumb: 'Organizations' },
            name: 'settings:organization',
            component: () => import('@/views/organization/Index.vue'),
            children: [
              {
                path: ':id/sites',
                name: 'settings:organization:sites',
                meta: { breadcrumb: 'Sites' },
                component: () => import('@/views/organization/Site.vue'),
              },
              {
                path: '',
                name: 'settings:organization',
                component: () => import('@/views/organization/Organization.vue'),
              },
            ],
          },
          {
            path: 'account',
            component: () => import('@/views/account/settings/Index.vue'),
            children: [
              {
                path: '',
                name: 'settings:account',
                redirect: '/console/settings/account/profile',
              },
              {
                path: 'profile',
                name: 'settings:account:profile',
                component: () => import('@/views/account/settings/Profile.vue'),
              },
              {
                path: '',
                name: 'account:settings',
                redirect: '/console/account/settings/profile',
              },
              {
                path: 'profile',
                name: 'account:settings:profile',
                component: () => import('@/views/account/settings/Profile.vue'),
              },
            ],
          },
          {
            path: 'audit/external',
            name: 'audit:external',
            meta: { middleware: [externalUserProtection] },
            component: () => import('@/views/reports/ExternalAudits.vue'),
          },
          {
            path: 'audit/external/:id',
            name: 'audit:external:details',
            meta: { middleware: [externalUserProtection] },
            component: () => import('@/views/reports/Audit.vue'),
          },
          {
            path: 'audit/external/history/:id',
            name: 'audit:external:history:details',
            meta: { middleware: [externalUserProtection] },
            component: () => import('@/views/reports/AuditHistory.vue'),
          },
        ],
      },

      {
        path: 'products/overview',
        component: () => import('@/views/product/Index.vue'),
        children: [
          {
            path: '',
            name: 'products:overview',
            component: () => import('@/views/product/Overview.vue'),
          },
          {
            path: ':id',
            name: 'products:overview:details',
            component: () => import('@/views/product/Details.vue'),
          },
        ],
      },
      {
        path: 'search-terms',
        name: 'searchterms:overview',
        component: () => import('@/views/SearchTerms.vue'),
      },
      {
        path: 'account/subscriptions',
        name: 'account:subscriptions',
        component: () => import('@/views/account/Subscriptions.vue'),
      },
      {
        path: 'account/billing',
        component: () => import('@/views/account/billing/Billing.vue'),
        children: [
          {
            path: '',
            redirect: '/console/account/billing/overview',
            name: 'account:billing',
            component: () => import('@/views/account/billing/Overview.vue'),
          },
          {
            path: 'overview',
            name: 'account:billing:overview',
            component: () => import('@/views/account/billing/Overview.vue'),
          },
          {
            path: 'profiles',
            name: 'account:billing:profiles',
            component: () => import('@/views/account/billing/Profiles.vue'),
          },
          {
            path: 'payment-methods',
            name: 'account:billing:cards',
            component: () => import('@/views/account/billing/Cards.vue'),
          },
          {
            path: 'invoices',
            name: 'account:billing:invoices',
            component: () => import('@/views/account/billing/Invoices.vue'),
          },
          {
            path: 'payment-methods/completed',
            name: 'payment-completed',
            component: () => import('@/views/account/billing/Completed.vue'),
          },
        ],
      },
      {
        path: 'campaigns',
        name: 'campaigns',
        component: () => import('@/views/campaigns/SiteCampaigns.vue'),
      },
      {
        path: 'campaign/feed',
        name: 'campaign:feed',
        component: () => import('@/views/Feed.vue'),
      },
      {
        path: 'performance-labels',
        name: 'performance:labels',
        component: () => import('@/views/PerformanceLabels.vue'),
      },
      {
        path: 'admin',
        name: 'admin',
        component: () => import('@/views/Admin.vue'),
      },
    ],
  },
  {
    path: '/audit',
    component: AuditLayout,
    children: [
      {
        path: '',
        name: 'audit',
        redirect: '/audit/signin',
      },
      {
        path: 'signin',
        name: 'audit-signin',
        component: () => import('@/views/audit/ConnectGoogleAds.vue'),
      },
      {
        path: 'report',
        name: 'audit-report',
        meta: { middleware: [auditAuth] },
        props: {
          isExternal: true,
        },
        component: () => import('@/views/reports/Audit.vue'),
      },
      {
        path: 'history',
        name: 'audit-report-history',
        meta: { middleware: [auditAuth] },
        props: {
          isExternal: true,
        },
        component: () => import('@/views/reports/AuditHistory.vue'),
      },
    ],
  },
]

function propagateMetadata(routes, meta) {
  routes.forEach(route => {
    //route.meta -> current route
    //meta -> parent route
    if (!route.meta) {
      route.meta = {}
    }
    route.meta.middleware = Object.values({
      ...(meta ? meta.middleware || {} : {}),
      ...(route.meta ? route.meta.middleware : {}),
    })

    if (route.children !== undefined) {
      propagateMetadata(route.children, route.meta)
    }
  })
}

propagateMetadata(routes, {})

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    ...routes,
    {
      path: '/console:pathMatch(.*)*',
      component: ConsoleLayout,
      children: [
        {
          path: '/console:pathMatch(.*)*',
          name: '404',
          component: () => import('@/views/PageNotFound.vue'),
          props: () => ({ theme: 'light' }),
        },
      ],
    },
    {
      path: '/:pathMatch(.*)*',
      component: FullPageLayout,
      children: [
        {
          path: '/:pathMatch(.*)*',
          name: '404',
          component: () => import('@/views/PageNotFound.vue'),
          props: () => ({ theme: 'dark' }),
        },
      ],
    },
  ],
})

// this piece of code is for removing the error on the console when navigating to the same route
const originalPush = router.push
router.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) {
    return originalPush.call(this, location, onResolve, onReject)
  }

  return originalPush.call(this, location).catch(err => {
    if (VueRouter.isNavigationFailure(err)) {
      return err
    }

    return Promise.reject(err)
  })
}
router.beforeEach(async (to, from, next) => {
  // Adjust the title based on the environment
  if (process.env.VUE_APP_ENVIRONMENT === 'development') {
    document.title = '[DEV] BrightBid - AI optimization for digital marketing'
  } else if (process.env.VUE_APP_ENVIRONMENT === 'beta') {
    document.title = '[BETA] BrightBid - AI optimization for digital marketing'
  } else {
    document.title = 'BrightBid - AI optimization for digital marketing'
  }

  router.app.$modal.hideAll()

  const matched = to.matched[2] ?? to.matched[1] ?? to.matched[0]
  if (!matched.meta.middleware || matched.meta.middleware.length === 0) {
    return next()
  }

  // Check if the route has a roles array
  if (matched.meta.roles) {
    // Check if the user's role is included in the roles array
    if (!matched.meta.roles.includes(role)) {
      // If it's not, redirect them to a different route
      return next('/404')
    }
  }

  const middleware = matched.meta.middleware
  const context = { to, from, next, store, router }

  middleware.forEach(middlewareFunction => {
    middlewareFunction(context)
  })
})

export default router
